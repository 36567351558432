import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import QRCode from "qrcode.react";
import { colors } from "../lib/theme";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { auth, db } from "../../shared/firebase";
import ContainerBox from "../../shared/ContainerBox";

const RoundQRCode = ({
  url,
  size = 200,
  bgColor = "#ffffff",
  fgColor = "#000000",
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <QRCode
        value={url}
        size={size}
        bgColor={bgColor}
        fgColor={fgColor}
        level="H"
        includeMargin={false}
      />
    </div>
  );
};

const GetStarted = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for password confirmation
  const [error, setError] = useState(null);

  const album_id = document
    .getElementById("react-root")
    .getAttribute("data-album-id");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const newUser = userCredential.user;

      // Create a new user record in the database
      await createUserInDatabase(newUser.uid, email);

      // Add the new user's UID to the album's sharedWith array
      const albumDocRef = doc(db, "albums", album_id);
      await updateDoc(albumDocRef, {
        sharedWith: arrayUnion(newUser.uid),
      });

      console.log("User created and associated successfully");
      window.location.href = "/welcome/";
    } catch (error) {
      console.error("Error creating user:", error);
      setError("Failed to create user. Please try again.");
    }
  };

  return (
    <>
      <Typography variant="h3" sx={{ color: colors.gold }}>
        You're almost there!
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: colors.white, pt: 1, fontSize: "1.3rem" }}
      >
        You can either finish creating your account now, or install the app and
        scan the QR code to get started! You can always create an account later.
      </Typography>

      <ContainerBox>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Create an Account
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                      {error}
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                  >
                    Get Started
                  </Button>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    borderRadius: "50%",
                    width: 50,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  OR
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  background: "#E9BE74",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Or Install and Scan
                </Typography>
                <Button
                  variant="contained"
                  href="https://apps.apple.com/app/golden-record/id6501951254"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 2,
                    fontSize: 12,
                  }}
                >
                  Download the app to your iPad or iPhone
                </Button>
                <p style={{ marginTop: "1rem" }}>
                  <strong>Once in the app</strong>: Scan the QR code below
                </p>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <RoundQRCode
                    url={`https://app.goldenrecord.app/album/${album_id}/`}
                    size={200}
                    fgColor="#000000"
                    bgColor="#E9BE74"
                  />
                </Box>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ mt: 2 }}
                  fontWeight="bold"
                >
                  SCAN WITH THE GOLDEN RECORD APP
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </ContainerBox>
    </>
  );
};

export const createUserInDatabase = async (newUID, email) => {
  try {
    // Reference to the new user's document in the Users collection
    const userDocRef = doc(db, "users", newUID);

    // Create a new user document with the new UID and email
    await setDoc(userDocRef, {
      userId: newUID,
      email: email,
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    console.log("New user created successfully");
  } catch (error) {
    console.error("Error creating new user:", error);
    throw new Error("Failed to create new user in the database.");
  }
};

export default GetStarted;
